<template>
  <header class="full-height-background">
<svg width="184px" height="177px" viewBox="0 0 184 177" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Cutout" fill="#000">
            <path d="M0,0 L0,177 L184,177 L184,0 L0,0 Z M55,57.4 L55.2,57.2 C55.3,57.1 57.4,54 63.6,54 C67.8,54 70.4,55.5 71.8,56.5 C72.8,55.6 75.1,54 79.6,54 C85.8,54 88.7,57.2 88.8,57.3 L89,57.6 L89,80 L87,80 L87,58.4 C86.2,57.7 83.9,56 79.6,56 C75.4,56 73.6,57.5 73,58.1 L73,72.8 L71,72.8 L71,58.4 C70.3,57.7 67.9,56 63.6,56 C59.4,56 57.6,57.5 57,58.1 L57,73 L55,73 L55,57.4 Z M55,77 L72,77 L78.1,95.6 L86,95.6 L86,87.6 L88,87.6 L88,97.6 L76.6,97.6 L73.1,87 L57,87 L57,104 L55,104 L55,77 Z M54.3,110 L55.7,108.6 L67.3,120 L86.2,120 L86.2,110.3 L70.3,110.3 L70.3,101 L88,101 L88,103 L72.3,103 L72.3,108.3 L88.2,108.3 L88.2,122 L66.5,122 L54.3,110 Z M129,121 L116.3,121 L116.3,105.5 C115.8,104.5 114.4,102.4 113,102.4 L113,102.3 L95,102.3 L95,122 L93,122 L93,88 L114.6,88 L114.8,88.1 C114.9,88.2 118,89.9 118,94.3 C118,97.4 116.3,100.1 115.4,101.3 C117.1,102.5 118.1,104.6 118.3,104.9 L118.4,105.1 L118.4,119 L127.1,119 L127.1,98 L129.1,98 L129.1,121 L129,121 Z M129,93 L127,93 L127,89 L129,89 L129,93 Z M129,84 L127,84 L127,77 L110.3,77 L105.2,84.2 L92,84.2 L92,76.4 L94,76.4 L94,82.2 L104.2,82.2 L115,67 L129,67 L129,84 Z M114.1,90 C114.6,90.4 116,91.6 116,94.3 C116,97.1 114.3,99.5 113.6,100.4 L95,100.4 L95,90 L114.1,90 Z M70.5,79 L72.5,85 L57,85 L57,79 L70.5,79 Z M127,69 L127,75 L111.8,75 L116,69 L127,69 Z M129,54 L129,56 L99.6,56 C99.6,56 94,56.2 94,59.4 C94,62.6 99.7,63 99.8,63 L129,63 L129,65 L99.7,65 C99.3,65 92,64.5 92,59.4 C92,54.3857143 98.694898,54.0206633 99.4420964,54.000984 L129,54 Z" id="MascariMotionLogo"></path>
        </g>
    </g>
</svg>
  </header>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
html, body, #app, header {
  margin: 0;
  padding: 0;
}
svg {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.full-height-background {
  height: 100vh;
  background-color: #000;
  align-items: center;
  text-align: center;
  background-image: url('./assets/bg.jpg');
  background-size:cover;
  // filter: saturate(50%);
  filter: sepia(30%);
}
</style>
